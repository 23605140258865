<template lang="html">
  <div class="login-page container">
    <div class="row justify-content-md-center">
      <div class="col-lg-6 auto">
        <b-form class="login-form" >
          <h1>Welcome to docking.id</h1>
          <div class="dotedd"></div>

          <b-form-group label-for="email">
            <b-form-input type="email" v-model="email" required placeholder="E-mail address" />
          </b-form-group>
          <b-form-group label-for="password">
            <b-form-input type="password" v-model="password" required placeholder="Password" />
          </b-form-group>
          <div class="form-opt">
            <a @click="login()" class="btn-primary">Login</a>
            <p href="#" class="lupa">Forget password? <a href="#">klik here</a></p>
          </div>
        </b-form>
        <!-- <p class="atau">
          <span>Or login with</span>
        </p>
        <div class="">
          <a @click="facebookLogin" class="btn facebook">Facebook</a>
          <a @click="googleLogin" class="btn google">Gmail</a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import accountAPI from '~/api/account.js'
import Cookie from 'js-cookie'

export default {
	middleware:"authentication",
	data() {
		return {
			email: null,
			password: null,
			socmed: null,
			name: null,
			show: true,
			isLogin: true
		}
	},
	methods: {
		onSubmit(evt) {
			evt.preventDefault()
			alert(JSON.stringify(this.form))
		},
		login(attr=null) {
	  		let accountApi = new accountAPI(this.$axios);
	  		let loginPayload = {};
			if(attr != null) {
				loginPayload = {
					email : attr.email,
					socmed: attr.type
				}
			} else {
				loginPayload = {
					email : this.email,
					password: this.password
				}
			}

			accountApi.doLogin(loginPayload).then(res => {

				if(res.data.type === "vendor") {
					Cookie.set('DOCKSID',res.data.session_id, {expires : 28, path : '/'});
					Cookie.set("USERTYPE",res.data.type)
					this.$store.dispatch('setAccount', res.data);
					window.location.pathname = "partner-dashboard"
				} else if(res.data.type === "shipyard") {
					Cookie.set('DOCKSID',res.data.session_id, {expires : 28, path : '/'});
					Cookie.set("USERTYPE",res.data.type)
					this.$store.dispatch('setAccount', res.data);
					window.location.pathname = "partner-dashboard/shipyard-facilities"
				} else {
					if(res.data.status == 1) { //already registered
						Cookie.set('DOCKSID',res.data.session_id, {expires : 28, path : '/'});
						Cookie.set("USERTYPE",res.data.type)
            this.$store.dispatch('setAccount', res.data);
            	window.location.pathname = "user-dashboard"
					} else {
						Cookie.set('tempEmailReg',res.data.email);
						window.location.pathname = 'create-account';
					}
				}


				this.$emit('close');

			}).catch(err => {
				if(err.response.status == 404) {
					if(this.isLogin) {
						alert('email is not registered, please register');
					}else {

						if(attr != null) {
							let regPayload = {
								email : attr.email,
								socmed: attr.type,
								name: attr.fullName,
								password: null,
								type: 'user'
							}
							Cookie.set('tempReg', JSON.stringify(regPayload));
						} else {
							Cookie.set('tempReg', JSON.stringify({email: this.email, name: this.name}));
						}

						window.location.pathname = 'create-account';
						this.$emit('close');
					}
				}
			})
		},
		facebookLogin(){
			let users = [];
			let payload = {};
			let _this = this;
			function authenticate(callback, errorCallback) {
				FB.getLoginStatus(function(response) {
					if (response.status === 'connected')
					{
						callback(response.authResponse);
					} else {
						FB.login(function(response)
						{
							if (response.authResponse)
							{
								callback(response.authResponse);
							} else {
								errorCallback && errorCallback(response);
								// resolve(response.error, null, deferred);
							}
						}, { scope: 'email' });
					}
				});
			}

			authenticate(function(authDetail){
				FB.api('/me?fields=id,name,email,picture,token_for_business&scope=email', function(response) {
					users['users_detail'] = response;
        			users['auth_detail'] = authDetail;

					payload = {
						email : response.email || _this.email,
						fullName : response.name,
						type : 'facebook'
					}
					if(payload.email){
						setTimeout(()=>{
						_this.login(payload)
						}, 1000)
					}
					// remove verified check
					// reason : https://www.facebook.com/help/196050490547892
					if (!response || response.error)
					{
						// resolve(response.error, null, deferred);
					}
				});
			}, function(error){

			});
		},
		googleLogin() {
			let data;
			let payload = {};
			let _this = this;
			window.google_auth2.signIn().then(() => {
				data = window.google_auth2.currentUser.get();
				payload = {
					// id : data.El,
					email : data.w3.U3,
					fullName : data.w3.ofa + ' '+data.w3.wea || data.w3.U3.substring(0, data.w3.U3.indexOf("@")),
					type : 'google',
            		// access_token : data.Zi.access_token,
				}
				this.login(payload);
			}).catch((err)=>{
				this.isLoading = false;
			})
		},
		onReset(evt) {
			evt.preventDefault()
			/* Reset our form values */
			this.email = ''
			this.password = ''
			/* Trick to reset/clear native browser form validation state */
			this.show = false
			this.$nextTick(() => {
				this.show = true
			})
		}
	}
}
</script>

<style lang="scss">
	@import '~/assets/scss/_mixins.scss';
	@import './style.scss';
</style>
